import React from "react";
import "../styles/Results.css";

const Results = ({ data }) => (
  <div className="results">
    <h2>Performance Results</h2>
    {data ? (
      <div>
        <p>
          Performance Score:{" "}
          {data.lighthouseResult.categories.performance.score * 100}
          <br />
          <p className="description">
            Description: Overall performance score, ranging from 0 to 1.
            Multiply by 100 for a percentage.
          </p>
        </p>
        <p>
          Largest Contentful Paint:{" "}
          {
            data.lighthouseResult.audits["largest-contentful-paint"]
              .displayValue
          }
          <br />
          <p className="description">
            Description: Measures loading performance. Good if below 2.5
            seconds.
          </p>
        </p>
        <p>
          First Input Delay:{" "}
          {data.lighthouseResult.audits["max-potential-fid"].displayValue}
          <br />
          <p className="description">
            Description: Measures interactivity. Good if below 100 milliseconds.
          </p>
        </p>
        <p>
          Cumulative Layout Shift:{" "}
          {data.lighthouseResult.audits["cumulative-layout-shift"].displayValue}
          <br />
          <p className="description">
            Description: Measures visual stability. Good if below 0.1.
          </p>
        </p>
        <p>
          First Contentful Paint:{" "}
          {data.lighthouseResult.audits["first-contentful-paint"].displayValue}
          <br />
          <p className="description">
            Description: Time it takes for the first piece of content to appear
            on the screen.
          </p>
        </p>
        <p>
          Speed Index:{" "}
          {data.lighthouseResult.audits["speed-index"].displayValue}
          <br />
          <p className="description">
            Description: Measures how quickly content is visually displayed
            during page load.
          </p>
        </p>
        <p>
          Time to Interactive:{" "}
          {data.lighthouseResult.audits["interactive"].displayValue}
          <br />
          <p className="description">
            Description: Measures how long it takes for the page to become fully
            interactive.
          </p>
        </p>

        <h3>Opportunities</h3>
        {data.lighthouseResult.audits["opportunities"] ? (
          data.lighthouseResult.audits["opportunities"].details.items.map(
            (item, index) => (
              <div key={index}>
                <p className="description">
                  {item.title}: {item.overallSavingsMs} ms
                </p>
              </div>
            )
          )
        ) : (
          <p className="description">No opportunities available.</p>
        )}
      </div>
    ) : (
      <p>No data available</p>
    )}
  </div>
);

export default Results;

// (
//   <div>
//         <p>
//           Performance Score:{" "}
//           {data.lighthouseResult.categories.performance.score * 100}
//           <br />
//           Description: Overall performance score, ranging from 0 to 1. Multiply
//           by 100 for a percentage.
//         </p>
//         <p>
//           Largest Contentful Paint (LCP):{" "}
//           {
//             data.lighthouseResult.audits["largest-contentful-paint"]
//               .displayValue
//           }
//           <br />
//           Description: Measures loading performance. Good if below 2.5 seconds.
//         </p>
//         <p>
//           First Input Delay (FID):{" "}
//           {data.lighthouseResult.audits["max-potential-fid"].displayValue}
//           <br />
//           Description: Measures interactivity. Good if below 100 milliseconds.
//         </p>
//         <p>
//           Cumulative Layout Shift (CLS):{" "}
//           {data.lighthouseResult.audits["cumulative-layout-shift"].displayValue}
//           <br />
//           Description: Measures visual stability. Good if below 0.1.
//         </p>
//         <p>
//           First Contentful Paint:{" "}
//           {data.lighthouseResult.audits["first-contentful-paint"].displayValue}
//           <br />
//           Description: Time it takes for the first piece of content to appear on
//           the screen.
//         </p>
//         <p>
//           Speed Index:{" "}
//           {data.lighthouseResult.audits["speed-index"].displayValue}
//           <br />
//           Description: Measures how quickly content is visually displayed during
//           page load.
//         </p>
//         <p>
//           Time to Interactive (TTI):{" "}
//           {data.lighthouseResult.audits["interactive"].displayValue}
//           <br />
//           Description: Measures how long it takes for the page to become fully
//           interactive.
//         </p>
//         <p>
//           <p>Provides suggestions for performance improvements</p>
//           {data.lighthouseResult.audits["opportunities"]}
//           <br />
//           Description: Contains a list of suggestions, each with a description
//           and potential savings.
//         </p>
//         <p>
//           <p>Provides additional information on performance issues.</p>{" "}
//           {data.lighthouseResult.audits["diagnostics"]}
//           <br />
//           Description: Contains various diagnostics data like network requests,
//           JavaScript execution time, etc.
//         </p>
//         {/* Add more metrics as needed */}
//       </div>
// )
