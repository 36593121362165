import axios from "axios";

export const fetchPerformanceData = async (url) => {
  try {
    const response = await axios.get(
      `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${url}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching performance data:", error);
    return null;
  }
};
