import React, { useState } from "react";
import Header from "./components/Header";
import URLForm from "./components/URLForm";
import Results from "./components/Results";
import "./styles/App.css";
import { fetchPerformanceData } from "./api";

const App = () => {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleAnalyze = async (url) => {
    setLoading(true);
    const data = await fetchPerformanceData(url);
    setResults(data);
    setLoading(false);
  };

  return (
    <div className="App">
      <Header />
      <URLForm onSubmit={handleAnalyze} loading={loading} />
      <Results data={results} />
    </div>
  );
};

export default App;
