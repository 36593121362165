import React, { useState } from "react";
import "../styles/URLForm.css";

const URLForm = ({ onSubmit, loading }) => {
  const [url, setUrl] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (url) {
      onSubmit(url);
    }
  };

  return (
    <form className="url-form" onSubmit={handleSubmit}>
      <input
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="Enter website URL with https://"
        required
      />
      <button type="submit" disabled={loading}>
        {loading ? "Loading ..." : "Analyze"}
      </button>
    </form>
  );
};

export default URLForm;
